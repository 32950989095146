// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Navigation from './components/Navigation';
import './styles/fonts.css';


const App = () => {
    return (
        <Router>
            {/* <Navigation /> */}
            <Routes>
                <Route path="/" element={<Home />} />
                {/* Placeholder routes for other pages */}
                <Route path="/about" element={<div><h2>About Page</h2></div>} />
                <Route path="/projects" element={<div><h2>Projects Page</h2></div>} />
                <Route path="/contact" element={<div><h2>Contact Page</h2></div>} />
            </Routes>
        </Router>
    );
};

export default App;
