import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./TestimonialsPage.css";

// Importing images
import person1Image from "../assets/joy.jpg"; // Replace with actual image path
import person2Image from "../assets/lucas.png"; // Replace with actual image path
import person3Image from "../assets/two.png"; // Replace with actual image path

const testimonials = [
  {
    name: "Joy Tirkey",
    position: "Music Producer",
    company: "Joysmusic",
    testimonial:
      "He is amazing! He delivered beyond expectations, and I couldn't be happier.",
    image: person1Image, // Replace with actual image URLs
  },
  {
    name: "Lucas Thapa",
    position: "Founder",
    company: "DEWT",
    testimonial:
      "Fantastic work! The communication and attention to detail were exceptional.",
    image: person2Image,
  },
  {
    name: "Harshit",
    position: "Founder",
    company: "Dreamscape Studios",
    testimonial:
      "Highly recommend!",
    image: person3Image,
  },
];

const TestimonialsPage = () => {
  useEffect(() => {
    AOS.init({
      once: false, // Trigger animations every time on scroll
      duration: 1000, // Animation duration
      easing: "ease-out", // Smooth transition
    });
  }, []);

  return (
    <div className="testimonials-page">
      {/* Testimonials Section */}
      <h2 className="section-title" data-aos="fade-up">
        What My Clients Say
      </h2>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <div
            className="testimonial-card"
            key={index}
            data-aos="fade-up"
            data-aos-delay={index * 100} // Adds a slight delay for staggered animations
          >
            <img
              src={testimonial.image}
              alt={`${testimonial.name}'s portrait`}
              className="testimonial-image"
            />
            <div className="testimonial-content">
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-position">
                {testimonial.position}, {testimonial.company}
              </p>
              <p className="testimonial-text">"{testimonial.testimonial}"</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsPage;
