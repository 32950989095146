import React, { useEffect } from "react";
import "./ProjectsPortfolio.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import images
import project1Img from "../assets/npm.png";
import project2Img from "../assets/media.jpeg";
import project3Img from "../assets/pw.jpeg";

const ProjectsPortfolio = () => {
  useEffect(() => {
    AOS.init({ once: true }); // Ensure animation happens only once
  }, []);

  const projects = [
    {
      id: 1,
      title: "Luminbox",
      description: "A React 18 package offering a gallery lightbox with zoom and navigation features.",
      imgSrc: project1Img,
      github: "https://github.com/TIRK3Y",
      liveDemo: "https://www.npmjs.com/~tirkey",
    },
    {
      id: 2,
      title: "Media Downloader",
      description: "A Python script-based tool to download media from 60+ platforms, including YouTube.",
      imgSrc: project2Img,
      github: "https://github.com/TIRK3Y/Media-Downloader",
      liveDemo: "https://github.com/TIRK3Y/Media-Downloader",
    },
    {
      id: 3,
      title: "Password Generator",
      description: "A JavaScript-powered tool for creating secure, random passwords.",
      imgSrc: project3Img,
      github: "https://github.com/TIRK3Y/Password-Generator",
      liveDemo: "https://tirk3y.github.io/Password-Generator/",
    },
  ];

  return (
    <div className="projects-portfolio">
      <h2 className="portfolio-title" data-aos="fade-up" data-aos-duration="1000">
        Recent Projects
      </h2>
      <div className="project-cards-container">
        {projects.map((project) => (
          <div
            className="project-card"
            key={project.id}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={`${project.id * 200}`} // Staggered reveal effect
          >
            <div className="card-image">
              <img src={project.imgSrc} alt={project.title} />
            </div>
            <div className="card-content">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div className="links-container">
                <a
                  href={project.github}
                  className="link-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github"></i>
                </a>
                <a
                  href={project.liveDemo}
                  className="link-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-link"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsPortfolio;
