"use client";

import { useEffect, useMemo, useState } from "react";
import { useTheme } from "next-themes";
import {
  Cloud,
  fetchSimpleIcons,
  ICloud,
  renderSimpleIcon,
  SimpleIcon,
} from "react-icon-cloud";

// Define the props for Cloud configuration
export const cloudProps: Omit<ICloud, "children"> = {
  containerProps: {
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: 40,
    },
  },
  options: {
    reverse: true,
    depth: 1,
    wheelZoom: false,
    imageScale: 2,
    activeCursor: "default",
    tooltip: "native",
    initial: [0.1, -0.1],
    clickToFront: 500,
    tooltipDelay: 0,
    outlineColour: "#0000",
    maxSpeed: 0.04,
    minSpeed: 0.02,
  },
};

// Custom icon renderer for dark and light themes
export const renderCustomIcon = (icon: SimpleIcon, theme: string) => {
  const bgHex = theme === "light" ? "#f3f2ef" : "#080510";
  const fallbackHex = theme === "light" ? "#6e6e73" : "#ffffff";
  const minContrastRatio = theme === "dark" ? 2 : 1.2;

  return renderSimpleIcon({
    icon,
    bgHex,
    fallbackHex,
    minContrastRatio,
    size: 42,
    aProps: {
      href: undefined,
      target: undefined,
      rel: undefined,
      onClick: (e: any) => e.preventDefault(),
    },
  });
};

// Define the list of icons and their official brand/logo colors
const iconData = [
    { slug: "amazonwebservices", color: "#FF9900" },  // AWS
    { slug: "mongodb", color: "#47A248" },  // MongoDB
    { slug: "mysql", color: "#4479A1" },  // MySQL
    { slug: "python", color: "#306998" },  // Python
    { slug: "javascript", color: "#F7DF1E" },  // JavaScript
    { slug: "shadcnui", color: "#212121" },  // ShadCN (No icon found)
    { slug: "adobephotoshop", color: "#31A8FF" },  // Adobe Photoshop
    { slug: "adobeillustrator", color: "#FF9A00" },  // Adobe Illustrator
    { slug: "adobepremierepro", color: "#9999FF" },  // Adobe Premiere Pro
    { slug: "react", color: "#61DAFB" },  // React.js
    { slug: "node-dot-js", color: "#68A063" },  // Node.js (corrected slug)
    { slug: "figma", color: "#F24E1E" },  // Figma
    { slug: "git", color: "#F05032" },  // Git
    { slug: "github", color: "#181717" },  // GitHub
    { slug: "androidstudio", color: "#3DDC84" },  // Android Studio
    { slug: "html5", color: "#E34F26" },  // HTML
    { slug: "css3", color: "#1572B6" },  // CSS
    { slug: "openai", color: "#10A37F" },  // ChatGPT (using OpenAI as slug)
    { slug: "googlegemini", color: "#8E75B2" },  // Gemini (may not exist)
    { slug: "adobe", color: "#FF0000" },  // Adobe Suite
    { slug: "visual-studio-code", color: "#007ACC" },  // VS Code
    { slug: "npm", color: "#CB3837" },
    { slug: "spotify", color: "#1DB954" },
    { slug: "shopify", color: "#7AB55C" },
    { slug: "firebase", color: "#DD2C00" },
    { slug: "socketdotio", color: "#010101" },
    { slug: "postman", color: "#FF6C37" },
  ];
  
  

export type DynamicCloudProps = {
  iconSlugs: string[];
};

type IconData = Awaited<ReturnType<typeof fetchSimpleIcons>>;

export function IconCloud({ iconSlugs }: DynamicCloudProps) {
  const [data, setData] = useState<IconData | null>(null);
  const { theme } = useTheme();

  useEffect(() => {
    // Fetch icons data based on slugs
    fetchSimpleIcons({ slugs: iconSlugs }).then((fetchedData) => {
      console.log("Fetched Icons Data:", fetchedData); // Debugging the fetched icons
      setData(fetchedData);
    });
  }, [iconSlugs]);

  // Memoized rendering of icons based on the current theme
  const renderedIcons = useMemo(() => {
    if (!data) return null;

    return iconData.map(({ slug, color }) => {
      const icon = data.simpleIcons[slug];
      if (icon) {
        return renderCustomIcon(icon, theme || "light");
      } else {
        console.warn(`Icon not found for slug: ${slug}`); // Log any missing icons
        return null;
      }
    });
  }, [data, theme]);

  return (
    <Cloud {...cloudProps}>
      {renderedIcons} {/* Render the icons inside Cloud component */}
    </Cloud>
  );
}
