import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { AnimationMixer } from 'three';
import { Suspense } from 'react';

// ModelWithAnimation Component
const ModelWithAnimation = React.forwardRef(({ scene, animations }, ref) => {
    const mixerRef = useRef();
    
    // Set scale for the model based on device type
    const modelScale = window.innerWidth < 768 ? 0.9 : 6; // Mobile vs Web scale

    // Set up animation mixer
    useEffect(() => {
        if (animations.length > 0) {
            const mixer = new AnimationMixer(scene);
            animations.forEach((clip) => {
                mixer.clipAction(clip).play(); // Play each animation
            });
            mixerRef.current = mixer;
        }
        

        return () => {
            // Clean up the mixer on unmount
            if (mixerRef.current) {
                mixerRef.current.stopAllAction();
            }
        };
    }, [animations, scene]);

    // Update animation on each frame
    useFrame((state, delta) => {
        if (mixerRef.current) {
            mixerRef.current.update(delta);
        }
    });

    return (
        <primitive
            object={scene}
            ref={ref} // Use the forwarded ref here
            scale={modelScale}
            position={[0, window.innerWidth < 768 ? 0.6 : 3.6, 0.3]} // Adjust position for mobile
            receiveShadow
        />
    );
});

// Main ThreeDModel Component
const ThreeDModel = () => {
    const { scene, animations } = useGLTF('/models/lost_programmer.glb'); // Load the model
    const modelRef = useRef();

    // Rotation logic for mouse and touch interactions
    const handlePointerMove = (e) => {
        if (modelRef.current) {
            const clientX = e.clientX || (e.touches && e.touches[0].clientX); // For touch devices
            const clientY = e.clientY || (e.touches && e.touches[0].clientY); // For touch devices

            modelRef.current.rotation.y = (clientX / window.innerWidth - 5.19) * Math.PI * .5;
            modelRef.current.rotation.x = (clientY / window.innerHeight - 0.5) * Math.PI * 0.2;
        }
    };

    useEffect(() => {
        // Add event listeners for both mouse and touch interactions
        window.addEventListener('mousemove', handlePointerMove);
        window.addEventListener('touchmove', handlePointerMove);

        return () => {
            // Clean up event listeners on unmount
            window.removeEventListener('mousemove', handlePointerMove);
            window.removeEventListener('touchmove', handlePointerMove);
        };
    }, []);

    return (
        <div
            style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}
        >
            <Canvas
                style={{ background: '#0131' }}
                shadows // Enable shadows for the model
            >
                <ambientLight intensity={0.3} />
                <directionalLight
                    position={[10, 10, 5]}
                    intensity={0.8}
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    shadow-camera-near={0.5}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10}
                />
                <pointLight
                    position={[-5, 5, -5]}
                    intensity={0.5}
                    castShadow
                />
                <OrbitControls enableZoom={false} enablePan={false} />

                {/* Render the model with animations */}
                <ModelWithAnimation scene={scene} animations={animations} ref={modelRef} />
            </Canvas>
        </div>
    );
};

// Wrapper with Suspense for loading state
const ThreeDModelWrapper = () => {
    return (
        <Suspense fallback={<div style={{ color: 'white' }}>Loading model...</div>}>
            <ThreeDModel />
        </Suspense>
    );
};

export default ThreeDModelWrapper;
