import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import './Video.css'; // We'll add styles here
import AOS from 'aos';
import 'aos/dist/aos.css';

const Video = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts
  }, []);

  return (
    <div className="video-section" data-aos="fade-up" data-aos-duration="1000">
      <h2 className="video-title" data-aos="fade-up" data-aos-duration="1000">
        Featured Video
      </h2>
      <div className="glass-container" data-aos="fade-up" data-aos-duration="1200">
        <div className="video-container" data-aos="zoom-in" data-aos-duration="1500">
          <ReactPlayer
            url="https://youtu.be/c2V7-Hi-c4g?si=5FX0wEOnSwLYllzD"
            width="100%" // Full width of the container
            height="100%" // Full height of the container
            playing={false} // Change this to `true` if you want it to autoplay
            controls={true} // Shows default YouTube controls, can be customized
            light={false} // Disable preview image (show the actual video player)
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
