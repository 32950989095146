import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Contact.css';

const Contact = () => {
  const [formStatus, setFormStatus] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
      easing: 'ease-out',
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_t81tfqf'; // Replace with your EmailJS Service ID
    const templateId = 'template_tf1b145'; // Replace with your EmailJS Template ID
    const userId = 'amHm7f3F_5G1SFp1n'; // Replace with your EmailJS Public Key (User ID)

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormStatus('Thank you for contacting! Will get back to you soon.');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setFormStatus('Oops! Something went wrong. Please try again.');
      });
  };

  return (
    <div className="contact-section" data-aos="fade-up">
      <div className="contact-card" data-aos="zoom-in" data-aos-delay="200">
        <h2 className="contact-title" data-aos="fade-up">
          Contact Me
        </h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group" data-aos="fade-right" data-aos-delay="300">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group" data-aos="fade-right" data-aos-delay="400">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group" data-aos="fade-right" data-aos-delay="500">
            <textarea
              name="message"
              placeholder="Your Message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group" data-aos="fade-up" data-aos-delay="600">
            <button className="submit-button" type="submit">
              Send Message
            </button>
          </div>
        </form>
        {formStatus && (
          <div className="form-status" data-aos="fade-up" data-aos-delay="700">
            {formStatus}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
