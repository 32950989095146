import React, { useState, useRef, useEffect } from 'react';
import './Music.css';
import sampleSong from '../assets/maya.aac'; // Import a sample audio file
import albumCover from '../assets/albumart.jpg'; // Import album cover image
import { FaSpotify, FaYoutube } from 'react-icons/fa'; // Icons for Spotify and YouTube Music
import AOS from 'aos';
import 'aos/dist/aos.css';

const Music = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(sampleSong)); // Use useRef to persist the audio object

  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts
  }, []);

  // Handle Play Button
  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause(); // Pause the audio
    } else {
      audioRef.current.play(); // Play the audio
    }
    setIsPlaying(!isPlaying);

    // When audio ends, reset the playing state
    audioRef.current.onended = () => setIsPlaying(false);
  };

  return (
    <div className="music-section">
      <h2 className="music-title" data-aos="fade-up" data-aos-duration="1000">
        Music
      </h2>
      <div className="recent-release">
        <h3 className="recent-release-title" data-aos="fade-up" data-aos-duration="1000">
          Recent Release
        </h3>
        <div className="music-card" data-aos="fade-up" data-aos-duration="1200">
          <img
            src={albumCover}
            alt="Album Cover"
            className="music-card-image"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
          <div className="music-card-details">
            <h4 className="song-name" data-aos="fade-up" data-aos-duration="1000">
              Maya
            </h4>
            <p className="artist-name" data-aos="fade-up" data-aos-duration="1000">
              Tirkey
            </p>
            <button className="play-button" onClick={togglePlay}>
              {isPlaying ? 'Pause' : 'Play'}
            </button>
          </div>
        </div>
        <div className="music-icons" data-aos="fade-up" data-aos-duration="1200">
          <a
            href="https://open.spotify.com/artist/5lzykkGu8zW3PwGuPUUWsA?si=xwHEj7Q0Rr26pYZlYURBRg"
            target="_blank"
            rel="noopener noreferrer"
            className="music-icon"
          >
            <FaSpotify size={40} />
          </a>
          <a
            href="https://www.youtube.com/@tirk3y"
            target="_blank"
            rel="noopener noreferrer"
            className="music-icon"
          >
            <FaYoutube size={40} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Music;
