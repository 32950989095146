import React from "react";
import { IconCloud } from "./IconCloud";
import "./Home.css"; // Reuse existing styles
import "./AboutMePage.css"; // Reuse existing styles

const AboutMe = () => {
  const iconSlugs = [
    "amazonwebservices", "mongodb", "mysql", "python", "javascript", "shadcnui", 
    "adobephotoshop", "adobeillustrator", "adobepremierepro", "react", "node-dot-js", 
    "figma", "git", "github", "androidstudio", "html5", "css3", "openai", "googlegemini", 
    "adobe", "visual-studio-code", "npm", "spotify", "firebase", "socketdotio", "postman",
  ];

  return (
    <section className="about-me">
      <div className="about-container">
        {/* Right section: Skills */}
        <div className="skills-container">
          <h3 className="skills-header">Proficiencies</h3>
          <div className="icon-cloud">
            <IconCloud iconSlugs={iconSlugs} />
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutMePage = () => {
  return (
    <div className="about-page">
      <AboutMe />
    </div>
  );
};

export default AboutMePage;
