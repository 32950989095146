import React, { useEffect } from 'react';
import { FaGlobe, FaLaptopCode, FaUniversity } from 'react-icons/fa';
import './Experience.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import your images
import freelanceImg from '../assets/albumart.jpg';
import fullstackImg from '../assets/eteam.png';
import uiDeveloperImg from '../assets/herody.jpg';
import mcaImg from '../assets/hover.png';
import bcaImg from '../assets/bca.png';

const Experience = () => {
  useEffect(() => {
    AOS.init({ once: true }); // Ensures the animation only happens once when the element scrolls into view
  }, []);

  return (
    <div className="experience-section">
      <h2 className="experience-title" data-aos="fade-up" data-aos-duration="1000">
        My Experience & Education
      </h2>
      <div className="timeline">
        {/* Freelance Work (5) */}
        <div className="timeline-item" data-aos="fade-up" data-aos-duration="1000">
          <div className="timeline-icon">
            <FaGlobe size={30} />
          </div>
          <div className="timeline-content">
            <h4>Fullstack Developer, UI & Graphics Designer</h4>
            <p>Freelance</p>
            <div className="timeline-image">
              <img src={freelanceImg} alt="Freelance" />
            </div>
          </div>
        </div>

        {/* Full Stack Developer - Intern (4) */}
        <div className="timeline-item" data-aos="fade-up" data-aos-duration="1100">
          <div className="timeline-icon">
            <FaLaptopCode size={30} />
          </div>
          <div className="timeline-content">
            <h4>Full Stack Developer - Intern</h4>
            <p>Eteam</p>
            <div className="timeline-image">
              <img src={fullstackImg} alt="Full Stack Developer" />
            </div>
          </div>
        </div>

        {/* UI Developer - Intern (3) */}
        <div className="timeline-item" data-aos="fade-up" data-aos-duration="1200">
          <div className="timeline-icon">
            <FaLaptopCode size={30} />
          </div>
          <div className="timeline-content">
            <h4>UI Developer - Intern</h4>
            <p>Herody</p>
            <div className="timeline-image">
              <img src={uiDeveloperImg} alt="UI Developer" />
            </div>
          </div>
        </div>

        {/* MCA (2) */}
        <div className="timeline-item" data-aos="fade-up" data-aos-duration="1300">
          <div className="timeline-icon">
            <FaUniversity size={30} />
          </div>
          <div className="timeline-content">
            <h4>MCA</h4>
            <p>Jain University</p>
            <div className="timeline-image">
              <img src={mcaImg} alt="MCA" />
            </div>
          </div>
        </div>

        {/* BCA (1) */}
        <div className="timeline-item" data-aos="fade-up" data-aos-duration="1400">
          <div className="timeline-icon">
            <FaUniversity size={30} />
          </div>
          <div className="timeline-content">
            <h4>BCA</h4>
            <p>Siliguri Institute of Technology</p>
            <div className="timeline-image">
              <img src={bcaImg} alt="BCA" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
