import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './FreelanceProjects.css';
// Import images
import project1Image from '../assets/2.JPG';
import project2Image from '../assets/founder.png';
import project3Image from '../assets/dss.png';

const FreelanceProjectCard = () => {
  useEffect(() => {
    AOS.init({ once: true }); // Initialize AOS library, animations will happen only once
  }, []);

  const freelanceProjects = [
    {
      id: 1,
      title: "Personal Website",
      company: "Joysmusic",
      name: "Joy Tirkey",
      image: project1Image,
      liveDemo: "https://joysmusic.in",
    },
    {
      id: 2,
      title: "NGO",
      company: "DEWT",
      name: "Lucas Thapa",
      image: project2Image,
      liveDemo: "https://prot.com",
    },
    {
      id: 3,
      title: "Production Agency",
      company: "Dreamscape Studios",
      name: "Harshit",
      image: project3Image,
      liveDemo: "https://project3.com",
    },
  ];

  return (
    <div className="freelance-projects-section">
      <h2
        className="freelance-projects-title"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Freelance Projects
      </h2>
      <div className="freelance-projects-container">
        {freelanceProjects.map((project) => (
          <div
            className="freelance-project-card"
            key={project.id}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={`${project.id * 200}`} // Add delay for staggered animation
          >
            <div className="freelance-card-image-wrapper">
              <img
                src={project.image}
                alt={project.title}
                className="freelance-card-image"
              />
              <div className="freelance-card-overlay">
                <div className="freelance-card-overlay-content">
                  <h3>{project.title}</h3>
                  <p>{project.name} - {project.company}</p>
                  <a
                    href={project.liveDemo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="freelance-link-icon"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FreelanceProjectCard;
