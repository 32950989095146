import React, { useRef, useEffect } from "react";
import ThreeDModel from "./ThreeDModel";
import "./Home.css";
import myImage from "../assets/bar.png";
import ProjectsPortfolio from "./ProjectsPortfolio";
import TestimonialsPage from "./TestimonialsPage";
import FreelanceProjects from "./FreelanceProjects";
import Music from "./Music";
import Video from "./Video";
import Experience from "./Experience";
import Contact from "./Contact";
import Footer from "./Footer";
import { FaLinkedin, FaBehance, FaSpotify, FaInstagram, FaYoutube } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import resumePdf from "../assets/Resume.pdf";
import AboutMePage from './AboutMePage';
import Languages from "./Languages";

const Home = () => {
  const modelWrapperRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
      offset: 200,
    });

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (modelWrapperRef.current) {
        modelWrapperRef.current.style.transform = `translateY(${scrollY * 0.1}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="hero">
        <div className="content">
          <div className="modelWrapper" ref={modelWrapperRef}>
            <ThreeDModel />
          </div>
          <h1 className="title2">
            <span className="line" data-aos="fade-up">PORTFOLIO</span>
          </h1>

          <div className="top-layer">
            <img src={myImage} alt="Decorative" className="rotated-image" />
          </div>

          <div className="description-section" data-aos="fade-up">
            <p className="description">
              I am <b>Ajay Tirkey</b>, a versatile developer, designer, and musician driven by a passion for creating innovative and meaningful projects. My journey combines technical expertise with artistic creativity, constantly learning and evolving to deliver impactful results.  
              <br />
              <span className="location">
                <i className="fas fa-map-marker-alt"></i> <b>Bengaluru, Karnataka - India</b>
              </span>
            </p>

            <button
              className="cta-button-hover"
              onClick={() => {
                const link = document.createElement("a");
                link.href = resumePdf;
                link.download = "Resume_Ajay_Tirkey.pdf";
                link.click();
              }}
            >
              Resume
            </button>
          </div>

          <div className="social-icons" data-aos="" data-aos-delay="200">
            <a
              href="https://www.linkedin.com/in/tirkey/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.behance.net/ajaytirkey"
              target="_blank"
              rel="noopener noreferrer"
              className="behance"
              aria-label="Behance"
            >
              <FaBehance />
            </a>
            <a
              href="https://open.spotify.com/artist/5lzykkGu8zW3PwGuPUUWsA?si=CWM1ZOkKT-23PEGU7hbZCQ"
              target="_blank"
              rel="noopener noreferrer"
              className="spotify"
              aria-label="Spotify"
            >
              <FaSpotify />
            </a>
            <a
              href="https://www.instagram.com/tirk3y"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.youtube.com/@tirk3y"
              target="_blank"
              rel="noopener noreferrer"
              className="youtube"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
        </div>

        <div className="scroll">
          <span className="scroll__line"></span>
          <span className="scroll__arrow">
            <svg width="17px" height="9px" viewBox="0 0 17 9">
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                strokeLinecap="square"
              >
                <g
                  id="Desktop_Screen-Copy-39"
                  transform="translate(-660.000000, -1241.000000)"
                  stroke="#FFFFFF"
                >
                  <g
                    id="Group-Copy-2"
                    transform="translate(660.000000, 1008.000000)"
                  >
                    <path d="M0.5,233.5 L8.5,241.5"></path>
                    <path d="M16.0178344,233.982166 L9,241"></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </div>
      </div>

      <Music />
      <Video />
      <Experience />
      <ProjectsPortfolio />
      <FreelanceProjects />
      <TestimonialsPage />
      <AboutMePage />
      {/* <Languages /> */}
      <Contact />
      
      {/* Full-width footer */}
      <div className="full-width-footer">
        <Footer />
      </div>
    </>
  );
};

export default Home;
