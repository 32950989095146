import React, { useState, useEffect } from "react";
import { FaLinkedin, FaInstagram, FaYoutube, FaSpotify, FaArrowUp } from "react-icons/fa";  // Import the icons
import "./Footer.css";

const Footer = () => {
  const [showScrollBtn, setShowScrollBtn] = useState(false);  // State to control button visibility
  const [reveal, setReveal] = useState(false);  // State to trigger animation

  useEffect(() => {
    // Function to check if the footer is in view and trigger reveal animation
    const handleScroll = () => {
      const footer = document.querySelector('.footer-section');
      const rect = footer.getBoundingClientRect();
      
      // Reveal the footer content when it is in view
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setReveal(true);
        setShowScrollBtn(true);  // Show the scroll button when footer is in view
      } else {
        setReveal(false);
        setShowScrollBtn(false);  // Hide the scroll button when footer is out of view
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={`footer-section ${reveal ? 'reveal' : ''}`}>
      <div className="footer-content">
        {/* Branding and Tagline */}
        <div className="footer-branding">
          <h2>Tirkey</h2>
          <p>I'm still learning and growing, and I know I might make mistakes along the way.</p>
          <p>Thank you for being part of this journey!</p>
        </div>

        {/* Contact Info */}
        <div className="footer-contact">
          <h3>Contact</h3>
          <p>Email: <a href="mailto:connect@tirkey.in">connect@tirkey.in</a></p>
          {/* <p>Phone: <a href="tel:+917892096399">+917892096399</a></p> */}

          <div className="social-icons">
            <a href="https://www.linkedin.com/in/tirkey/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/tirk3y/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/@tirk3y" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="https://open.spotify.com/artist/5lzykkGu8zW3PwGuPUUWsA?si=vRdsLRYtQ32M9WPX93bu-g" target="_blank" rel="noopener noreferrer">
              <FaSpotify />
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>
          &copy; 2024 Tirkey's Portfolio. All Rights Reserved | 
          <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer" className="footer-link"> tirkey.in</a>
        </p>
      </div>

      {/* Show the scroll-to-top button only when the footer is in view */}
      {showScrollBtn && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </footer>
  );
};

export default Footer;
